import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import { PusherProvider, useEvent, useChannel } from "@harelpls/use-pusher";
const config = {
  clientKey: "2111be3461dc944c2258",
  cluster: "us2",
};
ReactDOM.render(
  <PusherProvider {...config}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </PusherProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
