import { useState, useEffect } from 'react';
import { View, Director, Logger, StreamEvents } from '@millicast/sdk';
window.Logger = Logger;

Logger.setLevel(Logger.DEBUG);
const useVideoPlayer = (videoElement, id) => {
  const [playerState, setPlayerState] = useState({
    viewers: 0,
    isMuted: true,
  });
  const addStream = (stream) => {
    videoElement.current.srcObject = stream;
    videoElement.current.id = stream.id;
  };

  const newViewer = async (videoElement) => {
    const tokenGenerator = () => Director.getSubscriber(id, 'WY4WnW');
    const millicastView = new View(id, tokenGenerator, null, true);
    millicastView.on('broadcastEvent', (event) => {
      let layers = event.data['layers'] !== null ? event.data['layers'] : {};
      if (event.name === 'layers' && Object.keys(layers).length <= 0) {
      }
    });
    millicastView.on('track', (event) => {
      addStream(event.streams[0]);
    });
    try {
      const options = {
        disableVideo: false,
        disableAudio: false,
        absCaptureTime: true,
      };
      await millicastView.connect(options);
    } catch (error) {
      millicastView.reconnect();
    }
    //Create a new instance
    const streamEvents = await StreamEvents.init();
    const accountId = 'WY4WnW';
    const streamName = id;
    const options = {
      accountId,
      streamName,
      callback: (data) => {
        if (data.error) {
          console.error('Handle error: ', data.error);
        } else {
          setPlayerState({
            ...playerState,
            viewers: data.count,
          });
          console.log('Viewers: ', data.count);
        }
      },
    };

    //Initializes the user count event
    streamEvents.onUserCount(options);
    return millicastView;
  };

  useEffect(() => {
    videoElement.current.defaultMuted = true;
    videoElement.current.muted = true;
    newViewer(videoElement);
  }, [videoElement]);

  return { playerState };
};

export default useVideoPlayer;
