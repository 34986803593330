import './App.scss';
import useVideoPlayer from '../../hooks/useVideoPlayer';
import useInterval from '../../hooks/useInterval';
import { useEvent, useChannel } from '@harelpls/use-pusher';

import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime)

function App() {
  const videoElement = useRef(null);
  const [players, setPlayers] = useState([]);
  const [game, setGame] = useState(null);
  const [stream] = useState(window.stream);
  const [lastStreamed] = useState(window.stream.stoppedAt);
  const [scoreUser, setScoreUser] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [playersLoaded, setPlayersLoaded] = useState(false);
  const [streamOver, setStreamOver] = useState(false);
  const [mute, setMute] = useState(true);
  const { playerState } = useVideoPlayer(videoElement, stream.instanceId);

  const channel = useChannel(stream.id);
  useEvent(channel, 'score', ({ game, scoreUser }) => {
  
    updateGame(game);
    setScoreUser(scoreUser);
    setTimeout(() => {
      setScoreUser(null);
    }, 2000);
  });

  const sendEvent = (event, payload) => {
    fetch('/message', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        channel: `room-control-${stream.instanceId}`,
        event: event,
        payload,
      }),
    });
  };
  const updatePlayers = () => {
    fetch(`/meta?stream=${stream.id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result.stream.live) {
            setStreamOver(true);
            videoElement.current.pause()
            videoElement.current.currentTime=0 
            return;
          }
          setPlayers(
            result.users.sort((a, b) => a.username.localeCompare(b.username))
          );
          updateGame(result.game);
          setPlayersLoaded(true);
        },
        (error) => {}
      );
  };
  const updateGame = (game) => {
    if (game) {
      for (let key in game.players) {
        if (game.players.hasOwnProperty(key)) {
          if (!game[game.players[key].teamName]) {
            game[game.players[key].teamName] = [];
          }
          let player = players.find((o) => o.deviceId === key);
          if (player) {
            game[game.players[key].teamName].push(player);
          }
        }
      }
    }

    setGame(game);
  };

  const handleToggleMute = () => {
    videoElement.current.muted = !mute;
    setMute(!mute);
  };
  useInterval(() => {
    updatePlayers();
  }, 1000);
  useInterval(() => {
    setVideoLoaded(true);
  }, 2000);
  useEffect(() => {
    updatePlayers();
  }, []);

  useEffect(() => {
    if (playerState.viewers > 0) {
      setVideoLoaded(true);
    }
  }, [playerState]);

  return (
    <div>
      <div className={`Streamer ${streamOver ? '' : 'hide'}`}>
        <img
          className="Streamer__Avatar"
          src={stream.owner.profileImage}
          alt=""
        />
        <div className="Streamer__Name">{stream.owner.username}</div>
        <div className="Streamer__Last">Last streamed {dayjs(lastStreamed).fromNow()}</div>
        {/* <button className="Streamer__Follow">Follow</button> */}
      </div>
      <div
        className={`Preloader ${videoLoaded && playersLoaded ? 'gone' : ''}`}
      >
        <div className="Preloader__Label">Loading</div>
      </div>
      <div
        className={`container ${
          videoLoaded && playersLoaded ? 'show' : 'hide'
        }`}
      >
        <div className={`${scoreUser ? 'Bumper show' : 'Bumper'}`}>
          <span className="Bumper__Label">Score!</span>
          <span className="Bumper__Name">{scoreUser && scoreUser.username}</span>
        </div>

        <div className="Video">
          <div className="Spacer">&nbsp;</div>

          <div className="Video__Player">
            <div className="Video__Player__Control">
              <div class="Video__Player__Control__Camera hidden" style={{opacity: 0}}>
                <span
                  onClick={() => sendEvent('change-camera-event', 'previous')}
                >
                  ◂
                </span>
                <img src={'./icon-camera.svg'} alt="camera" />
                <span onClick={() => sendEvent('change-camera-event', 'next')}>
                  ▸
                </span>
              </div>

              <div className="Room">
                <span>{stream.room.name}</span>
                <span className="Room__Live">Live</span>
                <span>{playerState.viewers} viewers</span>
              </div>

              <div
                className="Video__Player__Control__Mute"
                onClick={handleToggleMute}
              >
                <img
                  className="svg"
                  src={`./icon-audio-${mute ? 'off' : 'on'}.svg`}
                  alt="audio"
                />
              </div>
            </div>

            <video
              className="Video__Player__Stream"
              ref={videoElement}
              autoPlay
              playsInline
              muted={true}
            ></video>
          </div>
        </div>

        {game && (
          <div className="GameNew">
            <h2>Current Match</h2>

            <div className="GameNew__Info">
              <div className="GameNew__Team">
                {game[game.teams[0]].map((player) => (
                  <div className="Avatar gameNew" key={player.deviceId}>
                    <img
                      className="Avatar__Image"
                      src={
                        player.profileImage ||
                        `https://avatars.dicebear.com/api/pixel-art-neutral/${player.deviceId}.svg`
                      }
                      alt={player.username}
                    />

                    <div className="Avatar__Name">{player.username}</div>
                  </div>
                ))}
              </div>

              <div className="GameNew__Score">
                <div className="label">{game.teams[0]}</div>

                <div className="score">
                  {game.scores[game.teams[0]]}
                  <span className="gameType">{game.typeName}</span>
                  {game.scores[game.teams[1]]}
                </div>

                <div className="label">{game.teams[1]}</div>
              </div>

              <div className="GameNew__Team">
                {game[game.teams[1]].map((player) => (
                  <div className="Avatar gameNew" key={player.deviceId}>
                    <img
                      className="Avatar__Image"
                      src={
                        player.profileImage ||
                        `https://avatars.dicebear.com/api/pixel-art-neutral/${player.deviceId}.svg`
                      }
                      alt={player.username}
                    />
                    <div className="Avatar__Name">{player.username}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="Sidebar">
          <div className="Players">
            <h2>Players</h2>

            <div className="Players__Avatars">
              {players.map((player) => (
                <div className="Avatar big" key={player.deviceId}>
                  <img
                    className="Avatar__Image"
                    src={
                      player.profileImage ||
                      `https://avatars.dicebear.com/api/pixel-art-neutral/${player.deviceId}.svg`
                    }
                    alt={player.username}
                  />
                  <div className="Avatar__Name">{player.username}</div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="Chat">
            <h2>Chat</h2>
            <div className="Chat__Messages"></div>
            <div className="Chat__Reactions">
              <div class="Chat__Reactions__Button">🔥</div>
              <div class="Chat__Reactions__Button">🙈</div>
              <div class="Chat__Reactions__Button">🏀</div>
              <div class="Chat__Reactions__Button">👟</div>
              <div class="Chat__Reactions__Button">💯</div>
            </div>
            <input
              className="Chat__Input"
              placeholder="Say something nice"
            ></input>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default App;
